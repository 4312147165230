.masonry {
	columns:5;
	column-gap: 1rem;
	width: 100%;
	position: relative;
	.masonryBrick {
		display:block;
		position: relative;
		margin: 0 auto 1.5rem;
		page-break-inside: avoid;
		div {
			margin: 0 auto;
		}
	}
}
/* Masonry on extra-large screens */
@media only screen and (max-width: 1750px) and (min-width: 1424px) {
	.masonry {
		columns: 4;
	}
}
/* Masonry on large screens */
@media only screen and (max-width: 1423px) and (min-width: 1024px) {
	.masonry {
		columns: 3;
	}
}
/* Masonry on medium-sized screens */
@media only screen and (max-width: 1023px) and (min-width: 768px) {
	.masonry {
		columns: 2;
	}
}
/* Masonry on small screens */
@media only screen and (max-width: 767px) and (min-width: 540px) {
	.masonry {
		columns: 1;
		padding-left: 1rem;
		padding-right: 1rem;
	}
}
/* Masonry on very small screens */
@media only screen and (max-width: 539px) {
	.masonry {
		columns: 1;
	}
}