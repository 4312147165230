.image_preview_container {
	display: flex;
	justify-content: center;
	background-color: #000;
}

.image_preview_image {
	width: 100%;
	max-width: 800px;
	height: auto;
	max-height: auto;
}

.open_composer_btn {
	width: 100%;
	margin-bottom: 20px;
}